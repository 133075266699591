import {
  ActionIcon,
  Box,
  Card,
  Center,
  Checkbox,
  Divider,
  Group,
  NumberInput,
  Radio,
  rem,
  Select,
  SimpleGrid,
  Stack,
  Text,
  TextInput,
  Title,
} from '@mantine/core'
import { useMemo } from 'react'
import { Add, Trash } from 'iconsax-react'
import { Controller, useFieldArray, useFormContext, useWatch } from 'react-hook-form'
import { POCLimitFormSchemaType } from './POCLimitPlayground'
import { GuaranteeItemEnum, GuaranteeItemTypeToCoverageTypeMap } from '../types'

const stringToColor = (value: string) => {
  let hash = 0
  for (let i = 0; i < value.length; i++) {
    hash = value.charCodeAt(i) + ((hash << 5) - hash)
  }

  return `hsl(${hash % 360}, 85%, 35%)`
}

const LimitInputs = ({ index }: { index: number }) => {
  const { control } = useFormContext<POCLimitFormSchemaType>()

  const watchedItems = useWatch({ control, name: 'items' })
  const { fields } = useFieldArray({
    control,
    name: 'items',
  })

  const items = fields.map((field, index) => ({ ...field, ...watchedItems[index] }))
  const isLimitsVisible = useMemo(() => {
    const currentItem = items[index]
    const filteredItems = items.filter((item) => item.guaranteeItemType === currentItem.guaranteeItemType)
    const itemIndex = filteredItems.findIndex((item) => item.id === currentItem.id)

    return itemIndex === 0
  }, [items, index])

  if (!isLimitsVisible) return null

  return (
    <>
      <Controller
        control={control}
        name={`items.${index}.limitPerUnit`}
        render={({ field }) => <NumberInput min={0} label="Limit / Unit" thousandSeparator=" " {...field} />}
      />
      <Controller
        control={control}
        name={`items.${index}.annualLimit`}
        render={({ field }) => <NumberInput min={0} label="Annual Limit" thousandSeparator=" " {...field} />}
      />
      <Controller
        control={control}
        name={`items.${index}.limitPerTransaction`}
        render={({ field }) => <NumberInput min={0} label="Limit / Transaction" thousandSeparator=" " {...field} />}
      />
    </>
  )
}

export const POCForm = () => {
  const { register, control } = useFormContext<POCLimitFormSchemaType>()

  const coverageType = useWatch({ control, name: 'coverageType' })

  const watchedItems = useWatch({ control, name: 'items' })

  const {
    fields,
    append: appendItem,
    remove: removeItem,
  } = useFieldArray({
    control,
    name: 'items',
  })

  const items = fields.map((field, index) => ({ ...field, ...watchedItems[index] }))

  return (
    <Stack gap={0}>
      <Title order={3}>Proof Of Coverage Playground</Title>
      <Stack mt={8}>
        <Text fw={700} td="underline" mb={-8}>
          Claim Details
        </Text>
        <Stack mb={12}>
          <Controller
            control={control}
            name="coverageType"
            render={({ field }) => (
              <Radio.Group {...field} name="coverageType" label="Coverage Type">
                <Group mt="xs">
                  <Radio value="prescription" label="Prescription" />
                  <Radio value="labwork" label="Labwork" />
                  <Radio value="consultation" label="Consultation" />
                  <Radio value="other" label="Other" />
                </Group>
              </Radio.Group>
            )}
          />
        </Stack>
        <Group align="flex-end">
          <Controller
            control={control}
            name="percentage"
            render={({ field }) => (
              <NumberInput
                {...field}
                max={100}
                min={0}
                step={10}
                label="Percentage"
                styles={{
                  input: {
                    width: rem(80),
                  },
                }}
              />
            )}
          />
          <Controller
            control={control}
            name="globalLimit"
            render={({ field }) => (
              <NumberInput {...field} w={120} min={0} step={10} thousandSeparator=" " label="Global Limit" />
            )}
          />
          <Checkbox pos="relative" bottom={12} label="Cash Advance" {...register('cashAdvance')} />{' '}
          <Checkbox pos="relative" bottom={12} label="Reimbursement" {...register('isReimbursement')} />{' '}
        </Group>
      </Stack>
      <Stack mt={24}>
        <Text fw={700} td="underline">
          Items
        </Text>
        <SimpleGrid cols={2}>
          {items.map((item, index) => {
            return (
              <Card
                withBorder
                pos="relative"
                key={item.id}
                styles={{
                  root: {
                    borderColor: stringToColor(item.guaranteeItemType),
                  },
                }}>
                <Box className="absolute top-1 right-1">
                  <ActionIcon color="alerts-red" variant="subtle" radius="xl" onClick={() => removeItem(index)}>
                    <Trash size={16} />
                  </ActionIcon>
                </Box>
                <SimpleGrid cols={2} verticalSpacing={6} spacing={24}>
                  <TextInput label="Name" {...register(`items.${index}.name`)} />
                  <Controller
                    control={control}
                    name={`items.${index}.guaranteeItemType`}
                    render={({ field }) => (
                      <Select
                        label="Guarantee Item Type"
                        searchable
                        allowDeselect={false}
                        data={Object.entries(GuaranteeItemEnum).map(([key, value]) => ({
                          value: value,
                          label: key
                            .replaceAll('_', ' ')
                            .toLowerCase()
                            .replace(/\b\w/g, (char) => char.toUpperCase()),
                        }))}
                        comboboxProps={{ width: 300, position: 'bottom-start' }}
                        {...field}
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name={`items.${index}.quantity`}
                    render={({ field }) => <NumberInput min={1} label="Quantity" thousandSeparator=" " {...field} />}
                  />
                  <Controller
                    control={control}
                    name={`items.${index}.pricePerUnit`}
                    render={({ field }) => (
                      <NumberInput min={0} label="Price / Unit" thousandSeparator=" " {...field} />
                    )}
                  />
                </SimpleGrid>
                <Divider variant="dashed" my={12} />
                <SimpleGrid cols={2} verticalSpacing={6} spacing={24}>
                  <LimitInputs index={index} />
                  <Checkbox
                    label="Excluded"
                    styles={{
                      root: {
                        position: 'relative',
                        alignSelf: 'end',
                      },
                    }}
                    {...register(`items.${index}.excluded`)}
                  />
                </SimpleGrid>
              </Card>
            )
          })}
          <Card
            withBorder
            styles={{
              root: {
                backgroundColor: 'var(--mantine-colors-gray-0)',
                borderStyle: 'dashed',
                borderWidth: rem(2),
              },
            }}>
            <Center flex={1}>
              <ActionIcon
                size={64}
                variant="subtle"
                onClick={() => {
                  appendItem({
                    name: `Item ${items.length + 1}`,
                    unitName: '',
                    quantity: 1,
                    pricePerUnit: 0,
                    excluded: false,
                    guaranteeItemType:
                      GuaranteeItemTypeToCoverageTypeMap[
                        coverageType as keyof typeof GuaranteeItemTypeToCoverageTypeMap
                      ],
                  })
                }}>
                <Add size={64} />
              </ActionIcon>
            </Center>
          </Card>
        </SimpleGrid>
      </Stack>
    </Stack>
  )
}
