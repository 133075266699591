import { useOrganizationDetails } from '@/features/make-payment/api/getOrganizationDetails'
import { useDecodeToken } from '@/features/token/api/decode-token'
import { Badge, Button, Divider, Drawer, Group, NumberFormatter, rem, Stack, Text } from '@mantine/core'
import { useIsMutating } from '@tanstack/react-query'
import { TickCircle } from 'iconsax-react'
import { useFormContext, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useMatch } from 'react-router-dom'
import { useGetPaymentBreakdown } from '../api/getPaymentBreakdown'

export const ConfirmPaymentDrawer = ({
  opened,
  onClose,
  onConfirm,
}: {
  opened: boolean
  onClose: () => void
  onConfirm: (amount: number) => void
}) => {
  const isB2B = Boolean(useMatch('/payment/make-payment-b2b/:token'))

  const { t } = useTranslation()
  const { data: decodedToken } = useDecodeToken()

  const { data: organizationDetails } = useOrganizationDetails({
    queryConfig: {
      enabled: isB2B,
    },
  })
  const { control } = useFormContext()
  const amount = useWatch({ control, name: 'amount' })

  const isLoading = useIsMutating({ mutationKey: ['makePayment'] })

  const percentage = decodedToken?.coverage_percentage ?? 0
  const cashAdvance = decodedToken?.has_cash_advance ?? false

  const { data: paymentBreakdown } = useGetPaymentBreakdown({
    claimId: decodedToken?.claim_id as string,
    amount: amount,
    enabled: opened,
  })

  if ((isB2B && !organizationDetails) || !decodedToken || !paymentBreakdown) return null

  return (
    <Drawer
      opened={opened}
      onClose={onClose}
      position="bottom"
      title={t('confirm_payment')}
      classNames={{
        inner: 'justify-center',
        content: 'h-auto rounded-t-3xl',
        title: 'font-bold text-lg',
        close: 'ring-1 ring-base-darkgray-6 rounded-full',
        header: 'pt-6',
      }}
      styles={{
        content: {
          maxWidth: rem(540),
        },
        close: {
          '--cb-size': '1.5rem',
          '--cb-icon-size': '0.75rem',
        },
      }}>
      <Stack mt={32} gap={24}>
        <Stack gap={16} className="text-text-default-base-gray-0">
          <Group justify="space-between">
            <Text data-autofocus fz={14} fw={400}>
              {`💰 ${t('sura_pays')} (${cashAdvance ? 100 : percentage}%)`}
            </Text>
            <Badge color="alerts-green" variant="outline">
              <NumberFormatter
                suffix=" F"
                value={
                  decodedToken.is_digitized
                    ? cashAdvance
                      ? paymentBreakdown.immediate_insurance_payment
                      : paymentBreakdown.expected_insurance_payment
                    : cashAdvance
                      ? amount
                      : (amount * percentage) / 100
                }
                decimalScale={1}
                thousandSeparator=" "
                className="font-bold"
              />
            </Badge>
          </Group>
          {!cashAdvance && (
            <Group justify="space-between">
              <Text fz={14} fw={400}>
                {`👉🏾 ${t('you_pay')}`}
              </Text>
              <Badge color="black" variant="outline" bg="white">
                <NumberFormatter
                  suffix=" F"
                  value={
                    decodedToken.is_digitized
                      ? paymentBreakdown.expected_user_payment
                      : ((100 - percentage) / 100) * amount
                  }
                  decimalScale={1}
                  thousandSeparator=" "
                  className="font-bold"
                />
              </Badge>
            </Group>
          )}
          <Divider variant="dashed" />
          <Text fz={14} fw={400}>
            {`🛡️ ${t('coverage_rate')}: `} <strong>{percentage}%</strong>
          </Text>
          <Text fz={14} fw={400} className='text-balance'>
            {`ℹ️ ${cashAdvance ? t('the_excess_will_be_deducted') : t('amount_at_your_expense')} (${[
              `${100 - percentage}%`,
              paymentBreakdown.excluded_amount > 0 && !cashAdvance ? t('exclusions') : '',
              paymentBreakdown.is_exceeded ? t('limit_exceeded') : '',
            ]
              .filter(Boolean)
              .join('+')}): `}
            <strong>
              <NumberFormatter
                suffix=" F"
                value={
                  decodedToken.is_digitized
                    ? cashAdvance
                      ? paymentBreakdown.total_claim_amount && amount < paymentBreakdown.total_claim_amount
                        ? paymentBreakdown.expected_user_payment
                        : paymentBreakdown.expected_user_payment - paymentBreakdown.excluded_amount
                      : paymentBreakdown.expected_user_payment
                    : ((100 - percentage) / 100) * amount
                }
                decimalScale={1}
                thousandSeparator=" "
              />
            </strong>
          </Text>
          {cashAdvance && paymentBreakdown.excluded_amount > 0 && (
            <Text fz={14} fw={400}>
              {`🚫 ${t('amount_at_your_expense_short')} (${t('exclusions')}): `}
              <strong>
                <NumberFormatter
                  suffix=" F"
                  value={paymentBreakdown.excluded_amount}
                  decimalScale={1}
                  thousandSeparator=" "
                />
              </strong>
            </Text>
          )}
          <Text fz={14} fw={400}>
            {decodedToken?.is_digitized
              ? 'ℹ️ NB : Cette répartition est une estimation et n’est pas définitive.'
              : 'ℹ️ NB : Vous serez contacté pour un remboursement si nous détectons des exclusions.'}
          </Text>
        </Stack>
        <Button
          mt={36}
          fullWidth
          styles={{
            root: {
              height: rem(55),
            },
          }}
          loading={!!isLoading}
          onClick={() => onConfirm(amount)}>
          <TickCircle />
          <Text ml={8} fw={700}>
            {t('validate')}
          </Text>
        </Button>
      </Stack>
    </Drawer>
  )
}
