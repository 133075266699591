import { z } from 'zod'

export const organizationDetailsSchema = z.object({
  name: z.string(),
  m_id: z.string(),
})

export const makePaymentResponseSchema = z.object({
  transaction_receipt_image_url: z.string().nullable(),
})

export const paymentBreakdownSchema = z.object({
  amount: z.number(),
  total_claim_amount: z.number().nullable().catch(null),
  coverage_rate: z.number(),
  immediate_insurance_payment: z.number(),
  expected_insurance_payment: z.number(),
  expected_user_payment: z.number(),
  excluded_amount: z.number().catch(0),
  is_exceeded: z.boolean().nullable().catch(null),
  already_paid: z.number().nullable().catch(null),
  limits: z
    .array(
      z.object({
        is_exceeded: z.boolean().nullable().catch(null),
        exceeded_by: z.number().nullable().catch(null),
        exceeded_limit: z.string().nullable().catch(null),
        limit_value: z.number().nullable().catch(null),
      }),
    )
    .catch([]),
})

export type MakePaymentResponse = z.infer<typeof makePaymentResponseSchema>
export type OrganizationDetails = z.infer<typeof organizationDetailsSchema>
export type PaymentBreakdown = z.infer<typeof paymentBreakdownSchema>
