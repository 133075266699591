import { api } from '@/lib/axios'
import { queryOptions, useQuery } from '@tanstack/react-query'
import { paymentBreakdownSchema } from '../types'

export const getPaymentBreakdownQueryOptions = (claimId: string, amount?: number) => {
  return queryOptions({
    queryKey: ['payment-breakdown', claimId, amount],
    queryFn: async () => {
      const data = await api.post('/coverage/payment-breakdown', null, {
        params: {
          transaction_id: claimId,
          amount,
        },
      })

      return paymentBreakdownSchema.parse(data)
    },
  })
}

export const useGetPaymentBreakdown = ({
  claimId,
  amount,
  enabled,
}: {
  claimId: string
  amount?: number
  enabled?: boolean
}) => {
  return useQuery({
    ...getPaymentBreakdownQueryOptions(claimId, amount),
    enabled,
  })
}
