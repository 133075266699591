import { Box, Button, Group, Slider, Stack, Text } from '@mantine/core'
import { DocumentDownload } from 'iconsax-react'
import { Liquid } from 'liquidjs'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { numericFormatter } from 'react-number-format'
import { POCLimitFormSchemaType } from './POCLimitPlayground'
import proofOfCoverage from './proof_of_coverage.html?raw'

const engine = new Liquid()

export const POCTemplate = () => {
  const iframeRef = useRef<HTMLIFrameElement>(null)

  const [coverageSize, setCoverageSize] = useState(16)
  const { control } = useFormContext<POCLimitFormSchemaType>()

  const globalLimit = useWatch({ control, name: 'globalLimit' })
  const annualLimit = useWatch({ control, name: 'annualLimit' })
  const transactionLimit = useWatch({ control, name: 'transactionLimit' })
  const percentage = useWatch({ control, name: 'percentage' })
  const cashAdvance = useWatch({ control, name: 'cashAdvance' })
  const isReimbursement = useWatch({ control, name: 'isReimbursement' })
  const coverageType = useWatch({ control, name: 'coverageType' })

  const items = useWatch({ control, name: 'items' })

  const getFormattedNumber = (value: number) => {
    if (isNaN(value) || value < 1) return '0'

    return numericFormatter(value?.toFixed(), {
      thousandSeparator: ' ',
    })
  }

  useEffect(() => {
    if (!iframeRef.current) return

    let updatedGlobalLimit = typeof globalLimit === 'number' ? globalLimit : undefined

    const filteredItems = items
      .filter((item) => item.name)
      .map((item) => {
        return {
          ...item,
          amount: getFormattedNumber(item.pricePerUnit * item.quantity),
        }
      })

    const guaranteeItems = filteredItems.reduce(
      (groups, item) => {
        const type = item.guaranteeItemType
        if (!groups[type]) {
          groups[type] = []
        }
        groups[type].push(item)
        return groups
      },
      {} as Record<string, typeof filteredItems>,
    )

    let totalCoverable = 0
    let totalAmount = 0
    let claimTotal = 0
    const finalItems = Object.entries(guaranteeItems).map(([key, value]) => {
      const item = value[0]
      const annualLimit = item.annualLimit
      const limitPerTransaction = item.limitPerTransaction
      const limitPerUnit = item.limitPerUnit

      const limitToDisplay = (() => {
        if (!limitPerTransaction && limitPerUnit) {
          return limitPerUnit + ' / Unit'
        }

        if (limitPerTransaction && !limitPerUnit) {
          return limitPerTransaction + ' / Transaction'
        }

        if (limitPerTransaction && limitPerUnit) {
          if (limitPerTransaction < limitPerUnit * item.quantity) {
            return limitPerTransaction + ' / Transaction'
          }
          return limitPerUnit + ' / Unit'
        }

        return '-------------'
      })()

      let itemRawTotal = 0
      let excludedTotal = 0
      value.forEach((item) => {
        itemRawTotal += item.excluded ? 0 : item.pricePerUnit * item.quantity
        claimTotal += item.pricePerUnit * item.quantity
        excludedTotal += item.excluded ? item.pricePerUnit * item.quantity : 0
      })

      const itemTotal = itemRawTotal * (percentage / 100)
      // prettier-ignore
      const unitLimitTotal = value.reduce((acc, el) => acc + (limitPerUnit || el.pricePerUnit) * el.quantity * (percentage / 100), 0)
      const transactionLimitTotal = limitPerTransaction ? limitPerTransaction * (percentage / 100) : Infinity

      const coverableAmount = Math.min(
        itemTotal,
        unitLimitTotal,
        transactionLimitTotal,
        typeof annualLimit === 'number' ? annualLimit : Infinity,
      )

      const finalCoverable = Math.min(
        coverableAmount,
        typeof updatedGlobalLimit === 'number' ? updatedGlobalLimit : Infinity,
      )
      if (updatedGlobalLimit) {
        updatedGlobalLimit = Math.max(0, updatedGlobalLimit - coverableAmount)
      }
      totalCoverable += finalCoverable
      totalAmount += itemRawTotal

      const finalExceeded = itemRawTotal - finalCoverable + excludedTotal

      return {
        items: value,
        coverable: getFormattedNumber(finalCoverable),
        exceeded: getFormattedNumber(finalExceeded),
        isLimitExceeded: itemRawTotal * (percentage / 100) > finalCoverable,
        limitToDisplay,
        percentage: percentage,
      }
    })
    const deductibleAmount = totalAmount - totalCoverable
    totalCoverable = cashAdvance ? totalAmount : totalCoverable
    const remainingAmount = claimTotal - totalCoverable

    const renderedHtml = engine.parseAndRenderSync(proofOfCoverage, {
      remSize: `${coverageSize}px`,
      claimTotal: getFormattedNumber(claimTotal),
      insurancePay: getFormattedNumber(totalCoverable),
      remainingAmount: getFormattedNumber(remainingAmount),
      deductibleAmount: getFormattedNumber(deductibleAmount),
      hasExceededAmount: totalAmount * (percentage / 100) > totalCoverable,
      hasExcludedAmount: items.some((item) => item.excluded),
      hasLimitExceeded: finalItems.some((item) => item.isLimitExceeded),
      percentage: percentage > 0 ? percentage.toFixed() : 0,
      cashAdvance,
      isReimbursement,
      coverageType,
      items: filteredItems,
      limits: [],
      guaranteeItems: finalItems,
    })

    const doc = iframeRef.current.contentDocument

    if (doc) {
      if (import.meta.env.DEV) {
        iframeRef.current.setAttribute('srcDoc', renderedHtml)
        return
      }
      doc.open()
      doc.write(renderedHtml)
      doc.close()
    }

    return () => {
      doc?.open()
      doc?.write('')
      doc?.close()
    }
  }, [
    cashAdvance,
    isReimbursement,
    coverageSize,
    coverageType,
    globalLimit,
    annualLimit,
    transactionLimit,
    items,
    percentage,
  ])

  const downloadPdf = () => {
    const iframe = iframeRef.current
    if (!iframe) return

    const doc = iframe.contentDocument
    if (!doc) return

    const printWindow = window.open('', '', 'height=768,width=1024')
    printWindow?.document.write(doc.documentElement.outerHTML)
    printWindow?.document.close()
    printWindow?.print()
  }

  return (
    <Stack h={'100%'} pos="relative">
      <iframe ref={iframeRef} className="flex-1 border-none ring-1 ring-gray-200 rounded-lg bg-white shadow-lg " />
      <Group justify="space-between">
        <Box>
          <Text size="sm">Font size</Text>
          <Slider w={200} min={12} max={24} value={coverageSize} onChange={(value) => setCoverageSize(value)} />
        </Box>
        <Button variant="light" color="alerts-green" leftSection={<DocumentDownload />} onClick={downloadPdf}>
          Download as PDF
        </Button>
      </Group>
    </Stack>
  )
}
