export enum GuaranteeItemEnum {
  CONSULTATION = 'consultation',
  SPECIALIST_CONSULTATIONS_VISITS = 'specialistConsultations',
  SPECIALIST_VISITS = 'specialistVisits',
  MEDICAL_PROCEDURES = 'medicalProcedures',
  CURATIVE_MEDICINES = 'curativeMedicines',
  VACCINES = 'vaccines',
  NURSING_CARE = 'nursingCare',
  PHYSIOTHERAPY_REHABILITATION = 'physiotherapy/Rehabilitation',
  TRANSPORT_BY_AMBULANCE_BY_LAND = 'transportByAmbulanceByLand',
  ORTHOPAEDICS = 'orthopaedics',
  SPEECH_THERAPY = 'speechTherapy',
  ORTHOPICS = 'orthopics',
  TRIPLE_THERAPY = 'tripleTherapy',
  ANALYSIS = 'analysis',
  SAMPLES = 'samples',
  RADIOLOGICAL_PROCEDURES = 'radiologicalProcedures',
  CT_SCAN = 'ctScan',
  ULTRASOUND = 'ultrasound',
  DENTAL_PROSTHETICS_AND_ORTHODONTICS = 'dentalProsthetics',
  RADIO_X_RAY = 'radioXRay',
  LENSES_AND_FRAMES = 'lensesAndFrames',
  ROOM_CHARGES_DURING_HOSPITALISATION = 'roomChargesDuringHospitalization',
  SUPPLEMENTARY_EXAMINATIONS = 'supplementaryExaminations',
  ADMINISTERED_PHARMACY = 'administeredPharmacy',
  ANCILLARY_CHARGES = 'ancillaryCharges',
  PRE_POSTNATAL_COSTS = 'preAndPostnatalCosts',
  NORMAL_SIMPLE_CHILDBIRTH = 'normalSimpleChildbirth',
  NORMAL_TWIN_CHILDBIRTH = 'normalTwinChildbirth',
  CHILDBIRTH_BY_SURGICAL_MEANS = 'childbirthBySurgicalMeans',
  CONSULTATION_DENTAL_SURGERY_CARE = 'consultationDentalSurgery',
  SURGICAL_FEES = 'surgicalFees',
}

export const GuaranteeItemTypeToCoverageTypeMap = {
  prescription: GuaranteeItemEnum.CURATIVE_MEDICINES,
  labwork: GuaranteeItemEnum.ANALYSIS,
  consultation: GuaranteeItemEnum.CONSULTATION,
  other: GuaranteeItemEnum.MEDICAL_PROCEDURES,
}
